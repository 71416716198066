import { memo } from 'react';

import { StatusTrackingAvatar } from '~/components/FileStatusTrackingPane/FileStatusTrackingPaneList/StatusTrackingAvatar';
import { PaneItem } from '~/components/FileStatusTrackingPane/PaneItem';
import { StatusTrackingSubtitle } from '~/components/FileStatusTrackingPane/StatusTrackingSubtitle';
import { StatusTrackingTitle } from '~/components/FileStatusTrackingPane/StatusTrackingTitle';
import { getSubtitleWithProgress } from '~/components/FileStatusTrackingPane/utils';

import { getHiddenUntilHoverStyles, paneListItemStyles, UploaderPaneListItemSharedProps } from '../shared';

export interface CompletedPaneListItemProps extends UploaderPaneListItemSharedProps {}

export const CompletedPaneListItem = memo(
  ({
    item: { ext, onRowClick, previewLoader, avatar, title, thumbnailSource, sizeInBytes, subtitle, status },
  }: CompletedPaneListItemProps) => {
    return (
      <PaneItem
        onClick={onRowClick}
        tx={{
          ...paneListItemStyles,
          ...getHiddenUntilHoverStyles({
            isCompleted: true,
            shouldAlwaysShowButtons: false,
            isClickable: !!onRowClick,
          }),
        }}
        previewLoader={previewLoader}
        avatar={avatar ?? <StatusTrackingAvatar ext={ext} status={status} thumbnailSource={thumbnailSource} />}
        title={<StatusTrackingTitle>{title}</StatusTrackingTitle>}
        subtitle={
          <StatusTrackingSubtitle>
            {subtitle ?? getSubtitleWithProgress({ title: 'Completed', uploadedBytes: sizeInBytes, sizeInBytes })}
          </StatusTrackingSubtitle>
        }
        buttons={null}
      />
    );
  },
);
CompletedPaneListItem.displayName = 'CompletedPaneListItem';
